<template>
  <checkout-step
    title="ecommerce.checkout.paymentMethod.stepTitle"
    :complete="stepData.isComplete"
    @checkout-step:request-edit="stepData.isComplete = false">
    <template #default>
      <form-input
        v-model="orderData.paymentMethod"
        theme-style="none"
        :auto-input-styling="false"
        type="largeOptions"
        :show-price="false"
        :error-message="paymentMethodSelectError"
        :option-component="'LargeOptionAddOn'"
        :list="paymentMethodFieldOptions"></form-input>

      <div class="payment-method-confirmation margin-xl-top">
        <form-button
          icon="check"
          text="ecommerce.checkout.paymentMethod.confirmCta"
          @click="attemptToCompleteStep"></form-button>
      </div>
    </template>
    <template #complete>
      <span class="text-l">
        <span class="text-s">{{ translateUcFirst(currentPaymentMethod.label) }} </span>
      </span>
    </template>
  </checkout-step>
</template>

<script>
import { computed } from "vue";
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import useCarts from "@/client/extensions/composition/useCarts.js";
import CheckoutStep from "@/client/components/ecommerce/checkout/CheckoutStep";
// we need to:
// 1. assign orderData.shippingMethodType - text
// 2. assign orderData.addressId - text

export default {
  components: {
    CheckoutStep,
  },
  props: {
    stepData: {
      type: Object,
      default: () => {},
    },
    orderData: {
      type: Object,
      default: () => {},
    },
    cart: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations();
    let carts = useCarts(props);
    return { asyncOps, asyncOpsReady, asyncStatus, carts };
  },
  data: function () {
    return {
      paymentMethodSelectError: "",
      paymentMethods: [],
      asyncData: {
        paymentMethods: {
          target: computed(() => `payment/gateway/cart/${this.cart.uuid}`),
          data: {},
          options: {
            dataMutator: (data, res) => {
              console.log("got payment options", data);
              return data;
            },
            errorHandler: (res) => {
              console.log("get payment options error", res);
            },
          },
        },
      },
    };
  },
  computed: {
    currentPaymentMethod() {
      if (!this.paymentMethods) {
        return false;
      }

      for (const method of this.paymentMethods) {
        if (method.type === this.orderData.paymentMethods) {
          return method;
        }
      }
      return false;
    },
    paymentMethodFieldOptions() {
      if (!this.paymentMethods || !Array.isArray(this.paymentMethods)) {
        return [];
      }

      let res = this.paymentMethods.map((item) => ({
        value: item.id,
        label: item.label,
        title: item.label,
        text: item.description,
        price: item.price,
        showPrice: false,
        currency: this.cart.quote.currency ? this?.cart?.quote?.currency : undefined,
        // selected: item.price === 0,
      }));

      return res;
    },
    canComplete() {
      return !!this.orderData.paymentMethod;
    },
  },
  watch: {
    paymentMethods(newVal) {
      if (Array.isArray(newVal) && newVal.length === 1) {
        this.orderData.paymentMethod = newVal[0].id;
      }
    },
    orderData: {
      deep: true,
      handler(newVal) {},
    },
  },
  methods: {
    attemptToCompleteStep(arg) {
      if (!this.canComplete) {
        this.paymentMethodSelectError = this.translate(
          "ecommerce.checkout.paymentMethod.selectPaymentValidationError"
        );
        return false;
      }
      this.stepData.isComplete = true;
    },
  },
};
</script>

<style scoped lang="scss"></style>
