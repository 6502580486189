<template>
  <div class="checkout-step relative" :class="{ complete: complete }">
    <h4 class="checkout-step-title" v-if="title && !complete">{{ translateUcFirst(title) }}</h4>
    <div class="checkout-step-inner bg-bg" style="">
      <div v-if="!complete" class="width-expand">
        <slot name="default"></slot>
      </div>
      <div v-if="complete" class="width-expand flex gap-m flex-middle">
        <icon-halo halo-theme="success" theme="bg" icon="check"></icon-halo>
        <span class="block">
          <h5 v-if="title && complete" class="margin-remove block text-l margin-m-bottom">
            {{ translateUcFirst(title) }}
          </h5>
          <slot name="complete"></slot>
        </span>
        <icon-halo class="edit-icon"
                   halo-theme="inverse"
                   theme="bg"
                   icon="pencil"
        @click="$emit('checkout-step:request-edit')"></icon-halo>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutStep",
  props: {
    title: {
      type: [String, Boolean],
      default: false,
    },
    complete: {
      type: [Boolean],
      default: false,
    },
  },
  data: function () {
    return {};
  },
  emits: [
    'checkout-step:request-edit'
  ]
};
</script>

<style scoped lang="scss">
.checkout-step {
  .checkout-step-title {
    margin-bottom: var(--margin-m);
    font-weight: 200;
    font-size: var(--font-3xl);
  }

  .checkout-step-inner {
    padding: var(--margin-xl) var(--margin-l);
  }

  &.complete {
    border: 1px solid var(--c-success);
    .checkout-step-inner {
      padding: var(--margin-l) var(--margin-l);
    }
  }

  .edit-icon {
    position: absolute;
    cursor: pointer;
    top: 0;
    inset-inline-start: 0;
    transform: translateY(-50%) translateX(-50%);

    [dir=rtl] & {
      transform: translateY(-50%) translateX(50%);
    }
  }
}
</style>
